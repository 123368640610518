import React from "react";

const Splashscreen = () => {
  return (
    <div className="flex">
      <div className="d-flex justify-content-center align-item-center mt-5  ">
        <h3>Welcome to Rupioo.com</h3>
      </div>
    </div>
  );
};

export default Splashscreen;
